import React from 'react';
import Options from '../Options';
import PageHeader from '../../tenant_portal/PageHeader';
import { useParams } from 'react-router-dom';

const ActivateOptions = () => {
  const { activationKey } = useParams();
  return (
    <>
      <PageHeader title="Activate login" />
      <Options
        emailPath={`/login/activate/${activationKey}/email`}
        activationKey={activationKey}
      />
    </>
  );
};

export default ActivateOptions;
